import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createMaterialShop } from "lib/api/api";
import { AlerMessage, MaterialShopParams } from "interfaces/index";
import MenuItem from '@mui/material/MenuItem';
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { isValueNotEmpty } from 'lib/shared_function/validation';

const theme = createTheme();

export default function CreateMaterialShop() {
  const navigate = useNavigate();

  const [name, setName] = useState<MaterialShopParams["name"]>("")
  const [status, setStatus] = useState <MaterialShopParams["status"]>('1')
  const [isValid, setIsValid] = useState<boolean>(false)

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      name: data.get('name'),
      staus: data.get('status'),
    });
  };

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleCreateMaterialShop = async () => {
    const params: MaterialShopParams = {
      name: name,
      status: status,
    };

    try {
      const res = await createMaterialShop(params)

      if (res.status === 200) {
        // 登録成功
        if (window.confirm('連続で登録しますか？')){
          window.location.reload()
        }else{
          navigate("/admin/material_shops", { state: { alert_message: "[建材屋] " + name + " を登録しました", alert_severity: 'success', alert_open: true } })
        }
      } else {
        handleAlertMessage("登録できませんでした", 'error')
      }
    } catch (err) {
      console.log(err)
      handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
    }
  }

  const KeyPress = (e: any) => {
    if (e.keyCode === 13 && isValid) {
      handleCreateMaterialShop();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          onKeyDown={(e) => KeyPress(e)} 
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            建材屋新規登録
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="建材屋名"
              name="名前"
              onChange={event => {
                setName(event.target.value)
                setIsValid(isValueNotEmpty(event.target.value))
              }}
              autoFocus
            />
            <FormControl  sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">状態</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                autoWidth
                label="状態"
              >
                <MenuItem value={1}>有効</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValid}
              onClick={handleCreateMaterialShop}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}