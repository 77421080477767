import { EmployeeParams, MaterialShopParams, ConstructionSiteParams, MaterialParams, AttendanceParams, AdminTopParams, EmployeeDataParams, CompanyParams, SignUpParams, DiscountParams, RowPageParams } from "interfaces"
import client from "lib/api/client"
import { createHeaders } from "lib/shared_function/create_headers"


////// 基本的にはheadersの情報からログインユーザをApiで取得している

//// 従業員
// 従業員新規登録
export const createEmployee = (params: EmployeeParams) => {
  return client.post("employees", { employee: params }, {headers: createHeaders()})
}
// 従業員一覧取得
export const getEmployees = () => {
  return client.get("employees", { headers: createHeaders()})
}
// 従業員編集画面
export const editEmployee = (id: string) => {
  return client.get("employees/" + id + '/edit', { headers: createHeaders() })
}
// 従業員編集画面
export const updateEmployee = (id: string, params: EmployeeParams) => {
  return client.put("employees/" + id, {employee: params }, { headers: createHeaders()})
}
// 従業員削除
export const deleteEmployee = (id: string) => {
  return client.delete("employees/" + id, { headers: createHeaders() })
}
// 従業員編集画面
export const showEmployee = (id: string, params: EmployeeDataParams) => {
  return client.get("employees/" + id, { headers: createHeaders(), params })
}

//// 建材屋
// 建材屋新規登録
export const createMaterialShop = (params: MaterialShopParams) => {
  return client.post("material_shops", { material_shop: params }, { headers: createHeaders()})
}
// 建材屋一覧取得
export const getMaterialShops = () => {
  return client.get("material_shops", { headers: createHeaders()})
}
// 建材屋編集画面
export const editMaterialShop = (id: string) => {
  return client.get("material_shops/" + id + '/edit', { headers: createHeaders() })
}
// 建材屋編集画面
export const updateMaterialShop = (id: string, params: MaterialShopParams) => {
  return client.put("material_shops/" + id, { material_shop: params }, { headers: createHeaders() })
}
// 建材屋削除
export const deleteMaterialShop = (id: string) => {
  return client.delete("material_shops/" + id, { headers: createHeaders() })
}

//// 現場
// 現場新規登録
export const createConstructionSite = (params: ConstructionSiteParams) => {
  return client.post("construction_sites", { construction_site: params }, { headers: createHeaders() })
}
// 現場一覧取得
export const getConstructionSites = () => {
  return client.get("construction_sites", { headers: createHeaders()})
}
// 現場編集画面
export const editConstructionSite = (id: string) => {
  return client.get("construction_sites/" + id + '/edit', { headers: createHeaders() })
}
// 現場編集画面
export const updateConstructionSite = (id: string, params: ConstructionSiteParams) => {
  return client.put("construction_sites/" + id, { construction_site: params }, { headers: createHeaders() })
}
// 現場削除
export const deleteConstructionSite = (id: string) => {
  return client.delete("construction_sites/" + id, { headers: createHeaders() })
}

//// 材料
// 材料新規登録
export const createMaterial = (params: MaterialParams) => {
  return client.post("materials", { material: params }, { headers: createHeaders() })
}
// 材料一覧取得
export const getMaterials = () => {
  return client.get("materials", { headers: createHeaders()})
}
// 材料編集画面
export const editMaterial = (id: string) => {
  return client.get("materials/" + id + '/edit', { headers: createHeaders() })
}
// 材料編集画面
export const updateMaterial = (id: string, params: MaterialParams) => {
  return client.put("materials/" + id, { material: params }, { headers: createHeaders() })
}
// 材料削除
export const deleteMaterial = (id: string) => {
  return client.delete("materials/" + id, { headers: createHeaders() })
}


//// 値引き
// 値引き新規登録
export const createDiscount = (params: DiscountParams) => {
  return client.post("discounts", { discount: params }, { headers: createHeaders() })
}
// 値引き一覧取得
export const getDiscounts = () => {
  return client.get("discounts", { headers: createHeaders() })
}
// 値引き編集画面
export const editDiscount = (id: string) => {
  return client.get("discounts/" + id + '/edit', { headers: createHeaders() })
}
// 値引き編集画面
export const updateDiscount = (id: string, params: DiscountParams) => {
  return client.put("discounts/" + id, { discount: params }, { headers: createHeaders() })
}
// 値引き削除
export const deleteDiscount = (id: string) => {
  return client.delete("discounts/" + id, { headers: createHeaders() })
}



//// 出面
// 出面新規登録
export const createAttendance = (params: AttendanceParams) => {
  return client.post("attendances", { attendance: params }, { headers: createHeaders() })
}
// 出面一覧取得
export const getAttendances = (params: RowPageParams) => {
  return client.get("attendances", { headers: createHeaders(), params })
}
// 出面編集画面
export const editAttendance = (id: string) => {
  return client.get("attendances/" + id + '/edit', { headers: createHeaders() })
}
// 出面編集画面
export const updateAttendance = (id: string, params: AttendanceParams) => {
  return client.put("attendances/" + id, { attendance: params }, { headers: createHeaders() })
}
// 出面削除
export const deleteAttendance = (id: string) => {
  return client.delete("attendances/" + id, { headers: createHeaders()})
}

// 管理画面Top 出面表
export const getAdminTop = (params: AdminTopParams) => {
  return client.get("admin_top", { headers: createHeaders(), params })
}

// 管理画面Top 一番古い登録年月取得
export const getOldestYear = () => {
  return client.get("useful_tools/oldest_registered_year", { headers: createHeaders()})
}

// 管理画面Top 登録従業員数とIDを取得
export const getEmployeesAttendancesData = (params: EmployeeDataParams) => {
  return client.get("useful_tools/employees_attendances_data", { headers: createHeaders(), params })
}

// 管理画面Top 登録従業員データを取得
export const getEmployeeData = (params: EmployeeDataParams) => {
  return client.get("useful_tools/employee_data", { headers: createHeaders(), params })
}

// 会社名取得
export const getComapnyName = () => {
  return client.get("useful_tools/company_name", { headers: createHeaders() })
}

// クライアント会社一覧取得
export const getCompaies = () => {
  return client.get("companies", { headers: createHeaders() })
}
// クライアント会社登録画面
export const createCompany = (params: CompanyParams) => {
  return client.post("companies", { company: params }, { headers: createHeaders() })
}
// クライアント会社編集画面
export const editCompany = (id: string) => {
  return client.get("companies/" + id + '/edit', { headers: createHeaders()})
}
// クライアント会社編集
export const updateCompany = (id: string, params: CompanyParams) => {
  return client.put("companies/" + id, { company: params }, { headers: createHeaders()})
}

// 管理者取得
export const getAdminUsers = () => {
  return client.get("admin_users/", { headers: createHeaders()})
}
// 管理者編集画面
export const editAdminUser = (id: string) => {
  return client.get("admin_users/" + id + '/edit', { headers: createHeaders() })
}
// 管理者編集
export const updateAdminUser = (id: string, params: SignUpParams) => {
  return client.put("admin_users/" + id, { admin_user: params }, { headers: createHeaders() })
}
// 管理者削除
export const deleteAdminUser = (id: string) => {
  return client.delete("admin_users/" + id, { headers: createHeaders() })
}