import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createEmployee } from "lib/api/api";
import { AlerMessage, EmployeeParams } from "interfaces/index";
import MenuItem from '@mui/material/MenuItem';
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputAdornment, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { isValueNotEmpty } from 'lib/shared_function/validation';

const theme = createTheme();

export default function CreateEmployee() {
  const navigate = useNavigate();
  const [is_valid, setIsValid] = useState<boolean>(false)
  const [name, setName] = useState<EmployeeParams["name"]>('')
  const [status, setStatus] = useState <EmployeeParams["status"]>('1')
  const [unit_price, setUnitPrice] = useState<EmployeeParams["unit_price"]>('')

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      name: data.get('name'),
      staus: data.get('status'),
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleCreateEmployee = async () => {
    if (is_valid){
      const params: EmployeeParams = {
        name: name,
        status: status,
        unit_price: unit_price,
      };

      try {
        const res = await createEmployee(params)

        if (res.status === 200) {
          // 登録成功
          if (window.confirm('連続で登録しますか？')) {
            window.location.reload()
          } else {
            navigate("/admin/employees", { state: { alert_message: "[従業員] " + name + " を登録しました", alert_severity: 'success', alert_open: true } })
          }
        } else {
          handleAlertMessage("登録できませんでした", 'error')
        }
      } catch (err) {
        console.log(err)
        handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
      }
    }
  }

  const KeyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleCreateEmployee();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            従業員/応援会社 新規登録
          </Typography>
          <Box component="form" onKeyDown={(e) => KeyPress(e)} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="従業員/応援会社名"
              name="名前"
              onChange={event => {
                setName(event.target.value)
                setIsValid(isValueNotEmpty(event.target.value))
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="unit_price"
              label="単価"
              name="単価"
              helperText="※半角数字"
              InputProps={{
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}
              onChange={event => {
                setUnitPrice(event.target.value)
              }}
              autoFocus
            />
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">状態</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                autoWidth
                label="状態"
              >
                <MenuItem value={2}>応援会社</MenuItem>
                <MenuItem value={1}>従業員</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            </FormControl>
            <Button
              fullWidth
              disabled={!is_valid}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCreateEmployee}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}