import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createMaterial, getConstructionSites, getMaterialShops } from "lib/api/api";
import { AlerMessage, MaterialParams } from "interfaces/index";
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputAdornment } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';

const theme = createTheme();

export default function CreateMaterial() {
  const navigate = useNavigate();
  // const [is_valid, setIsValid] = useState<boolean>(false);

  const [material_shops, setMaterialShop] = useState<[]>([])
  const [construction_sites, setConstructionSites] = useState<[]>([])

  const [date, setDate] = useState<MaterialParams["date"]>("")

  const [cost, setCost] = useState<MaterialParams["cost"]>("")

  const [construction_site_id, setConstructionSiteId] = useState<MaterialParams["construction_site_id"]>('')

  const [material_shop_id, setMaterialShopId] = useState<MaterialParams['material_shop_id']>('')

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  useEffect(() => {
    getMaterialShops().then((res) => {
      const shops = res.data.data.filter((shop: any) =>
        shop["status"] !== 0
      )
      setMaterialShop(shops?.map((material_shop: any) => {
        delete material_shop['createdAt']
        delete material_shop['updatedAt']
        delete material_shop['status']
        return material_shop
      }))
    });
    getConstructionSites().then((res) => {
      const sites = res.data.data.filter((construction_site: any) => 
        construction_site["status"] !== 0
      )
      setConstructionSites(sites?.map((construction_site: any) => {
        delete construction_site['createdAt']
        delete construction_site['updatedAt']
        delete construction_site['status']
        return construction_site
      }))
    })
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      id: data.get('id'),
      staus: data.get('status'),
    });
  };

  const KeyPress = (e: any) => {
    if (e.keyCode === 13){
      handleCreateMaterial();
    }
  }

  const handleCreateMaterial = async () => {
    if (date && cost && material_shop_id && construction_site_id){
      const params: MaterialParams = {
        date: date,
        cost: cost,
        material_shop_id: material_shop_id,
        construction_site_id: construction_site_id,
      };

      try {
        const res = await createMaterial(params)

        if (res.status === 200) {
          // 登録成功
          if (window.confirm('連続で登録しますか？')) {
            window.location.reload()
          } else {
            navigate("/admin/materials", { state: { alert_message: "[材料]を登録しました", alert_severity: 'success', alert_open: true } })
          }
        } else {
          handleAlertMessage("登録できませんでした", 'error')
        }
      } catch (err) {
        console.log(err)
        handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
      }
    }
  }

  const handleDateChange = (newValue: Date | null) => {
    if (newValue) {
      const date_string = String(new Date(newValue))
      setDate(date_string);
    } else {
      setDate('')
    }
  };

  const handleMaterialShopChange = (id: any) => {
    setMaterialShopId(id);
  };

  const handleConstructionSiteChange = (id: any) => {
    setConstructionSiteId(id);
  };


  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose} >
        <CssBaseline />
        <Box
          onKeyDown={(e) => KeyPress(e)} 
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            材料新規登録
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="仕入れ日"
                  inputFormat="YYYY/MM/DD"
                  value={date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            <FormControl sx={{ my: 1, mt: 2, minWidth: '100%' }}>
              <Autocomplete
                id="material_shop_select"
                sx={{ minWidth: 100 }}
                options={material_shops ? material_shops : []}
                autoHighlight
                getOptionLabel={(option: any) => option ? option.name : ''}

                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(event: any, newValue: any) => {
                  const material_shop_id = newValue ? newValue.id : ''
                  handleMaterialShopChange(material_shop_id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="建材屋"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                value={
                  material_shops.length > 0 ?
                    material_shop_id ? material_shops.find((ms) => ms['id'] === material_shop_id) : null
                    :
                    null
                }
              />
            </FormControl>
            <br />
            <FormControl sx={{ my: 1, minWidth: '100%' }}>
              <Autocomplete
                id="construction_site_select"
                sx={{ minWidth: 100 }}
                options={construction_sites ? construction_sites : []}
                autoHighlight
                getOptionLabel={(option: any) => option ? option.name : ''}

                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(event: any, newValue: any) => {
                  const construction_site_id = newValue ? newValue.id : ''
                  handleConstructionSiteChange(construction_site_id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="現場"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                value={
                  construction_sites.length > 0 ?
                    construction_site_id ? construction_sites.find((cs) => cs['id'] === construction_site_id) : null
                    :
                    null
                }
              />
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="cost"
              label="材料費"
              InputProps={{
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}
              onChange={event => {
                setCost(event.target.value)
              }}
              autoFocus
              helperText="※半角数字"
            />
            <Button
              fullWidth
              variant="contained"
              disabled={false}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCreateMaterial}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}