import { useEffect, useState } from 'react';
import { getCompaies } from 'lib/api/api';
import { Container, Grid } from '@mui/material';
import { SuperAdminTopCompanyTypes } from "interfaces/index";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatDate } from 'lib/shared_function/form_date';
import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const columns = [
  { label: 'ID', minWidth: 30 },
  { label: 'クライアント会社名', minWidth: 150 },
  { label: 'ステータス', minWidth: 70 },
  { label: '登録日', minWidth: 70 },
  { label: '更新日', minWidth: 70 },
];

export default function SuperAdminTop() {
  const [rows, setRows] = useState<SuperAdminTopCompanyTypes[]>()

  const handleSetRows = (res: any) => {
    const status_hash = { "0": "無効", "1": "有効", "99": "最高権限" } as {[key: string]: string }
    setRows(res?.map((row: SuperAdminTopCompanyTypes) => {
      row['status'] = status_hash[row['status']]
      return row
    }))
  }

  useEffect(() => {
    getCompaies().then((res) => {
      handleSetRows(res.data.data)
    })
  }, [])

  // const handleHideDeleteTable = (id: string) => {
  //   if (hoverId === id) {
  //     setHoverId('')
  //   }
  // }

  // const handleShowDeleteTable = (id: string) => {
  //   setHoverId(id)
  // }

  // const handleDelete = (id: string) => {
  //   deleteAttendance(id).then(() => {
  //     getAttendances().then((res) => {
  //       setAttendances(res.data);
  //     });
  //   })
  // }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={0}>
        <Box style={{ width: '100%', marginTop: '5%', margin: '5% auto 1% auto', textAlign: 'right' }}>
          <Link style={{ textDecoration: 'none' }} to={'/super_admin/company/new'} >
            <Button variant="contained" style={{ background: "#87c9a7" }}>
              新規作成
            </Button>
          </Link>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns?.map((column, i) =>
                  <TableCell key={i} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>{column.label}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell >{row.name}</TableCell>
                  <TableCell >{row.status}</TableCell>
                  <TableCell >{formatDate(new Date(row.createdAt))}</TableCell>
                  <TableCell >{formatDate(new Date(row.updatedAt))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Container>
  );
}