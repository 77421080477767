import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createAttendance, getConstructionSites, getEmployees } from "lib/api/api";
import { AlerMessage, AttendanceParams } from "interfaces/index";
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';


const theme = createTheme();

export default function CreateAttendance() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [is_valid, setIsValid] = useState<boolean>(false);

  const [employees, setEmployees] = useState<[]>([])
  const [construction_sites, setConstructionSites] = useState<[]>([])

  const [date, setDate] = useState<AttendanceParams["date"]>(String(new Date()))
  const [construction_site_id, setConstructionSiteId] = useState<AttendanceParams["construction_site_id"]>('')
  const [employee_id, setEmployeeId] = useState<AttendanceParams['employee_id']>('')
  const [overtime_hours, setOvertimeHours] = useState<AttendanceParams['overtime_hours']>('')
  const [construction_type, setConstructionType] = useState<AttendanceParams['construction_type']>('0')

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')
  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  useEffect(()=> {
    if (location.state){
      setDate(location.state['date'])
      setEmployeeId(location.state['employee_id'])
    }
    getEmployees().then((res) => {
      const employees = res.data.data.filter((employee: any) =>
        employee['status'] !== 0
      )
      setEmployees(employees?.map((employee: any) => {
        delete employee['createdAt']
        delete employee['updatedAt']
        delete employee['status']
        delete employee['companyId']
        delete employee['unitPrice']
        return employee
      }))
    });
    getConstructionSites().then((res) => {
      const sites = res.data.data.filter((site: any) =>
        site['status'] !== 0
      )
      setConstructionSites(sites?.map((construction_site: any) => {
        delete construction_site['createdAt']
        delete construction_site['updatedAt']
        delete construction_site['status']
        return construction_site
      }))
    })
  }, [location.state])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      name: data.get('name'),
      staus: data.get('status'),
    });
  };

  const handleCreateAttendance = async () => {
    if (date && employee_id && construction_site_id){
      const params: AttendanceParams = {
        date: date,
        employee_id: employee_id,
        construction_site_id: construction_site_id,
        overtime_hours: overtime_hours,
        construction_type: construction_type,
      };

      try {
        const res = await createAttendance(params)

        if (res.status === 200) {
          // 登録成功
          if (window.confirm('連続で登録しますか？')) {
            window.location.reload()
          } else {
            navigate("/admin/attendances", { state: { alert_message: "[出面]を登録しました", alert_severity: 'success', alert_open: true } })
          }
        } else {
          handleAlertMessage("登録できませんでした", 'error')
        }
      } catch (err) {
        console.log(err)
        handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
      }
    }
  }

  const handleDateChange = (newValue: Date | null) => {
    if (newValue){
      const date_string = String(new Date(newValue))
      setDate(date_string);
    }else{
      setDate('')
    }
  };

  const handleEmployeeChange = (id: any) => {
    setEmployeeId(id);
  };

  const handleConstructionSiteChange = (id: any) => {
    setConstructionSiteId(id);
  };

  const KeyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleCreateAttendance();
    }
  }

  const handleConstructionType = (event: SelectChangeEvent) => {
    setConstructionType(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          onKeyDown={(e) => KeyPress(e)} 
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            出面新規登録
          </Typography>
          <Box component="form"  onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="日付"
                  inputFormat="YYYY/MM/DD"
                  value={date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            <FormControl sx={{ my: 1, mt:2, minWidth: '100%' }}>
              <Autocomplete
                id="employee_select"
                sx={{ minWidth: 100 }}
                options={employees ? employees : []}
                autoHighlight
                getOptionLabel={(option: any) => option ? option.name : ''}

                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(event: any, newValue: any) => {
                  const employee_id = newValue ? newValue.id : ''
                  handleEmployeeChange(employee_id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="従業員/応援会社"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                value={
                  employees.length > 0 ?
                    employee_id ? employees.find((emp) => emp['id'] === employee_id) : null
                    :
                    null
                }
              />
            </FormControl>
            <br/>
            <FormControl sx={{ my: 1, minWidth: '100%' }}>
              <Autocomplete
                id="construction_site_select"
                sx={{ minWidth: 100 }}
                options={construction_sites ? construction_sites : []}
                autoHighlight
                getOptionLabel={(option: any) => option ? option.name : ''}

                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(event: any, newValue: any) => {
                  const construction_site_id = newValue ? newValue.id : ''
                  handleConstructionSiteChange(construction_site_id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="現場"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                value={
                  construction_sites.length > 0 ?
                    construction_site_id ? construction_sites.find((cs) => cs['id'] === construction_site_id) : null
                    :
                    null
                }
              />
            </FormControl>
            <FormControl sx={{ marginTop: 1, minWidth: 100 }}>
              <InputLabel id="demo-simple-select-autowidth-label">工事タイプ</InputLabel>
              <Select
                value={construction_type}
                onChange={handleConstructionType}
                autoWidth
                label="工事タイプ"
              >
                <MenuItem value={0}>本工事</MenuItem>
                <MenuItem value={1}>追加工事</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="contruct_fee"
              label="残業時間"
              name="残業時間"
              InputProps={{
                endAdornment: <InputAdornment position="end">時間</InputAdornment>,
              }}
              onChange={event => {
                setOvertimeHours(event.target.value)
              }}
              autoFocus
              helperText="※半角数字"
            />
            <Button
              fullWidth
              variant="contained"
              disabled ={false}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCreateAttendance}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}