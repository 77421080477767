import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { updateConstructionSite, editConstructionSite } from "lib/api/api";
import { AlerMessage, ConstructionSiteParams } from "interfaces/index";
import MenuItem from '@mui/material/MenuItem';
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputAdornment, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const theme = createTheme();

export default function EditConstructionSite() {
  const navigate = useNavigate();
  const location = useLocation()

  const [is_valid, setIsValid] = useState<boolean>(true);

  const [name, setName] = useState<ConstructionSiteParams["name"]>("")
  const [status, setStatus] = useState <ConstructionSiteParams["status"]>('1')
  const [contruct_fee, setContructFee] = useState<ConstructionSiteParams['contructFee']>('')

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  const handleVaidation = (value: string, item: string) => {
    if(item === 'name'){
      if(contruct_fee !== null && value.length > 0){
        setIsValid(true)
      }else{
        setIsValid(false)
      }
    }else if(item === 'fee'){
      if(name.length > 0 && value.length > 0){
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    }
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      name: data.get('name'),
      staus: data.get('status'),
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleCreateConstructionSite = async () => {
    if (is_valid){
      const params: ConstructionSiteParams = {
        name: name,
        status: status,
        contructFee: contruct_fee,
      };

      try {
        const res = await updateConstructionSite(location.state.constructionSiteId, params)

        if (res.status === 200) {
          // 登録成功
          navigate("/admin/construction_sites", { state: { alert_message: "[現場] " + name + " を変更しました", alert_severity: 'success', alert_open: true } })
        } else {
          handleAlertMessage("登録できませんでした", 'error')
        }
      } catch (err) {
        console.log(err)
        handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
      }
    }
  }

  useEffect(() => {
    if (location.state && location.state.constructionSiteId) {
      editConstructionSite(location.state.constructionSiteId).then((res) => {
        setContructFee(String(res.data.fee))
        setName(res.data.name)
        setStatus(res.data.status)
      })
    } else {
      navigate('/admin/construction_sites', { state: { alert_message: '不正なアクセスです', alert_severity: "error", alert_open: true } })
    }
  }, [location.state, navigate])

  const KeyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleCreateConstructionSite();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            現場編集
          </Typography>
          <Box component="form" onKeyDown={(e) => KeyPress(e)} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              value={name}
              id="name"
              label="現場名"
              name="名前"
              onChange={event => {
                setName(event.target.value)
                handleVaidation(event.target.value, 'name')
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="contruct_fee"
              label="契約金"
              value={contruct_fee}
              name="契約金"
              InputProps={{
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}
              onChange={event => {
                setContructFee(event.target.value)
                handleVaidation(event.target.value, 'fee')
              }}
              autoFocus
              helperText="※半角数字"
            />
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">状態</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                autoWidth
                label="状態"
              >
                <MenuItem value={1}>有効</MenuItem>
                <MenuItem value={0}>無効</MenuItem>
              </Select>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              disabled ={!is_valid}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCreateConstructionSite}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}