import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAttendance, getAttendances } from 'lib/api/api';
import { Attendance } from 'interfaces';
import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Column {
  id: 'constructionSiteName' | 'constructionType' | 'employeeName' | 'date';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'date', label: '日付', minWidth: 70 },
  { id: 'constructionType', label: '工事タイプ', minWidth: 70 },
  { id: 'employeeName', label: '従業員/応援会社名', minWidth: 150 },
  { id: 'constructionSiteName', label: '現場名', minWidth: 170 },
];

export default function IndexAttendances() {
  const navigate = useNavigate()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const [attendances, setAttendances] = React.useState<Attendance[]>([])
  const [hoverId, setHoverId] = React.useState<string>('');
  const [attendances_size, setAttendancesSize] = React.useState<number>(0)
  const [loaded_pages, setLoadedPages] = React.useState<number[]>([0])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (!loaded_pages.includes(newPage)){
      setLoadedPages(loaded_pages.concat(newPage))
      const params = {
        page: newPage,
        row: rowsPerPage
      }
      getAttendances(params).then((res) => {
        res.data.data.map((attendance: any) => {
          if (attendance['constructionType'] === 0) {
            attendance['constructionType'] = '本工事'
          } else {
            attendance['constructionType'] = '追加工事'
          }
          return attendance
        })
        setAttendances(attendances.concat(res.data.data));
      });
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadedPages([0])
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGoToEditPage = (id: string) =>{
    navigate('/admin/attendance/edit/' + id, {state: {attendanceId: id}})
  }

  React.useEffect(()=>{
    const params = {
      page: page,
      row: rowsPerPage
    }
    getAttendances(params).then((res) => {
      res.data.data.map((attendance: any) => {
        if (attendance['constructionType'] === 0){
          attendance['constructionType'] = '本工事'
        }else{
          attendance['constructionType'] = '追加工事'
        }
        return attendance
      })
      setAttendances(res.data.data);
      setAttendancesSize(res.data.size.attendancesSize)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  const handleHideDeleteTable = (id: string) => {
    if(hoverId === id){
      setHoverId('')
    }
  }

  const handleShowDeleteTable = (id: string) => {
    setHoverId(id)
  }

  const handleDelete = (id: string) => {
    const params = {
      page: page,
      row: rowsPerPage
    }
    if (window.confirm('削除してもよろしいですか？')) {
      deleteAttendance(id).then(() => {
        getAttendances(params).then((res) => {
          setAttendances(res.data);
        });
      })
    }
  }

  return (
    <React.Fragment>
      <Box style={{ width: '85%', marginTop: '2.5%', margin: '5% auto 1% auto', textAlign: 'right' }}>
        <Link style={{ textDecoration: 'none' }} to={'/admin/attendance/new'} >
          <Button variant="contained" style={{ background: "#87c9a7" }}>
            新規作成
          </Button>
        </Link>
      </Box>
      <Paper style={{ width: '85%', overflow: 'hidden', margin: '0 auto' }}>
        <TableContainer sx={{ maxHeight: 630 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {attendances
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i} onMouseOver={()=> handleShowDeleteTable(row.id)} onMouseLeave={() => handleHideDeleteTable(row.id)}>
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      {hoverId === row.id
                      ?
                        < TableCell align={'right'}>
                          <Tooltip title="編集" style={{ margin: '0 10%' }}>
                            <IconButton onClick={() => handleGoToEditPage(row.id)}>
                              <EditIcon sx={{ color: 'skyblue' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="削除">
                            <IconButton onClick={()=> handleDelete(row.id)}>
                              <DeleteIcon sx={{ color: 'tomato' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      :
                        <TableCell />
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          component="div"
          count={attendances_size}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
