import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom';
import { deleteConstructionSite, getConstructionSites } from 'lib/api/api';
import { ConstructionSite } from 'interfaces';
import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Column {
  id: 'name' | 'status' | 'contructFee';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'name', label: '現場名', minWidth: 170 },
  { id: 'contructFee', label: '契約金', minWidth: 100 },
  { id: 'status', label: '状態', minWidth: 70 },
];

export default function IndexConstructionSites() {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const [construction_site, setConstructionSite] = React.useState<ConstructionSite[]>([])

  const [hoverId, setHoverId] = React.useState<string>('');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleGoToEditPage = (id: string) => {
    navigate('/admin/construction_site/edit/' + id, { state: { constructionSiteId: id } })
  }

  React.useEffect(()=>{
    getConstructionSites().then((res) => {
      setConstructionSite(
          res.data.data?.map((construction_site: any) => {
          delete construction_site['createdAt']
          delete construction_site['updatedAt']
          construction_site['contructFee'] = construction_site['contructFee'].toLocaleString() + "円"
          if (construction_site['status'] === 1) {
            construction_site['status'] = '有効'
          } else if (construction_site['status'] === 0) {
            construction_site['status'] = '無効'
          }
          return construction_site
        })
      );
    });
  }, [])

  const handleHideDeleteTable = (id: string) => {
    if (hoverId === id) {
      setHoverId('')
    }
  }

  const handleShowDeleteTable = (id: string) => {
    setHoverId(id)
  }

  const handleDelete = (id: string) => {
    if (window.confirm('削除してもよろしいですか？')) {
      deleteConstructionSite(id).then(() => {
        getConstructionSites().then((res) => {
          setConstructionSite(
            res.data.data?.map((construction_site: any) => {
              delete construction_site['createdAt']
              delete construction_site['updatedAt']
              construction_site['contructFee'] = construction_site['contructFee'].toLocaleString() + "円"
              if (construction_site['status'] === 1) {
                construction_site['status'] = '有効'
              } else if (construction_site['status'] === 0) {
                construction_site['status'] = '無効'
              }
              return construction_site
            })
          );
        });
      })
    }
  }

  return (
    <React.Fragment>
      <Box style={{ width: '85%', marginTop: '2.5%', margin: '5% auto 1% auto', textAlign: 'right' }}>
        <Link style={{ textDecoration: 'none' }} to={'/admin/construction_site/new'}>
          <Button variant="contained" style={{ background: "#87c9a7" }}>
            新規作成
          </Button>
        </Link>
      </Box>
      <Paper style={{ width: '85%', overflow: 'hidden', margin: '0 auto' }}>
        <TableContainer sx={{ maxHeight: 630 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {construction_site
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i} onMouseOver={() => handleShowDeleteTable(row.id)} onMouseLeave={() => handleHideDeleteTable(row.id)}>
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      {hoverId === row.id
                        ?
                        < TableCell align={'right'}>
                          <Tooltip title="編集" style={{ margin: '0 10%' }}>
                            <IconButton onClick={() => handleGoToEditPage(row.id)}>
                              <EditIcon sx={{ color: 'skyblue' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="削除">
                            <IconButton onClick={() => handleDelete(row.id)}>
                              <DeleteIcon sx={{ color: 'tomato' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        :
                        <TableCell />
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          component="div"
          count={construction_site.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
