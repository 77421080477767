import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { showEmployee } from "lib/api/api";
import { EmployeeDataParams } from "interfaces/index";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import { Box, Button, Grid, Link, Paper } from '@mui/material';
import { DatesSetArg } from '@fullcalendar/core';
import EmployeeTable from 'components/tables/employe_table';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

const now = new Date()
export default function ShowEmployee() {
  const navigate = useNavigate();
  const location = useLocation()
  const [employee_attendances, setEmployeeAttendances] = useState<[]>([])
  const [month, setMonth] = useState<string>(String(now.getMonth() + 1))
  const [year, setYear] = useState<string>(String(now.getFullYear()))
  const [attendance_array, setAttendanceArray] = useState<[]>([])

  const handleDatesChange = (arg: DatesSetArg) => {
    if (new Date(arg.endStr).getMonth() === 0)
      setMonth(String(12))
    else
      setMonth(String(new Date(arg.endStr).getMonth()))
    setYear(String(new Date(arg.endStr).getFullYear()))
  };
  useEffect(() => {
    const params: EmployeeDataParams = {
      year: year,
      month: month
    }
    showEmployee(location.state.employeeId, params).then((res) => {
      setEmployeeAttendances(res.data)
      setAttendanceArray(res.data['attendanceArray'])
    })
  }, [location.state, month, navigate, year])

  const handleDateClick = useCallback((arg: DateClickArg) => {
    navigate('/admin/attendance/new', { state: { date: arg.dateStr, employee_id: location.state.employeeId }})
  }, [location.state.employeeId, navigate]);

  return (
    <>
      <Box style={{ width: '85%', margin: '2% auto 0 auto', textAlign: 'left' }}>
        <Link style={{ textDecoration: 'none' }} href='/admin/employees' underline="none">
          <Button variant="contained" style={{ background: "rgb(135 176 201)" }}>
            {"＜ "}従業員/応援会社一覧へ戻る
          </Button>
        </Link>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ width: '86%', margin: '0 auto' }}
      >
        <Paper style={{ width: '60%', overflow: 'hidden', margin: '3% auto' }}>
          <div style={{ width: '95%', overflow: 'hidden', margin: '3% auto' }}>
            <FullCalendar
              initialView="dayGridMonth"
              locales={[jaLocale]}
              locale='ja'
              headerToolbar={{
                left: 'prev',
                center: 'title',
                right: 'next today',
              }}
              datesSet={handleDatesChange}
              events={attendance_array}
              eventColor={'orange'}
              eventTextColor={'black'}
              plugins={[dayGridPlugin, interactionPlugin]}
              dateClick={handleDateClick}
              eventClick={(e) => { navigate('/admin/attendance/edit/' + e.event.id, { state: { attendanceId: e.event.id } }) }}
            />
          </div>
        </Paper>
        <EmployeeTable key={location.state.employeeId} employee_attendances={employee_attendances} width_adjuster={"39%"}/>
      </Grid>
    </>
  );
}