import DrawerAppBar from "components/appbar"
import CustomizedSnackbars from "components/snackbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { AlerMessage } from 'interfaces/index';
import { getCurrentUser, getCompanyStatus } from "lib/api/auth";

export default function SuperAdmin() {
  const location = useLocation()
  const navigate = useNavigate();
  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>(null)
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  useEffect(() => {
    getCompanyStatus().then((res) => {
      if (res.data.data === 99){
        getCurrentUser().then((res) => {
          if (res.data.isLogin) {
            if (location.state !== null) {
              setAlertMessage(location.state.alert_message)
              setAlertSeverity(location.state.alert_severity)
              setAlertOpen(location.state.alert_open)
            }
          } else {
            navigate('/', { state: { alert_message: "セッションが切れました", alert_severity: 'error', alert_open: true } })
          }
        })
      }else{
        navigate('/', { state: { alert_message: "不正なアクセスです", alert_severity: 'error', alert_open: true } })
      }
    })
  }, [location.state, navigate])

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
      navigate(location.pathname, { state: { alert_message: "", alert_severity: 'info', alert_open: false } }
      );
    } else {
      return;
    }
  };

  return (
    <div onClick={handleAlertClose}>
      <DrawerAppBar />
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Outlet />
    </div>
  );
}