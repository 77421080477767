import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { deleteEmployee, getEmployees } from 'lib/api/api';
import { Employee } from 'interfaces';
import { Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Column {
  id: 'name' | 'status'| 'unitPrice';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'name', label: '従業員/応援会社名', minWidth: 170 },
  { id: 'status', label: '状態', minWidth: 70 },
  { id: 'unitPrice', label: '単価', minWidth: 70 },
];

export default function IndexEmployees() {
  const navigate = useNavigate()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const [employees, setEmployees] = React.useState<Employee[]>([])
  const [hoverId, setHoverId] = React.useState<string>('');
  const status_hash = { 0: "無効", 1: "従業員", 2: "応援会社" } as any

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleGoToEditPage = (id: string) => {
    navigate('/admin/employee/edit/' + id, { state: { employeeId: id } })
  }

  const handleGoToShowPage = (id: string) => {
    navigate('/admin/employee/show/' + id, { state: { employeeId: id } })
  }

  React.useEffect(()=>{
    getEmployees().then((res) => {
      setEmployees(
        res.data.data?.map((employee: any) => {
        delete employee['createdAt']
        delete employee['updatedAt']
        employee['status'] = status_hash[employee['status']]
          if (employee['unitPrice']){
            employee['unitPrice'] = employee['unitPrice'].toLocaleString() + "円"
          }
        return employee
        })
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleHideDeleteTable = (id: string) => {
    if (hoverId === id) {
      setHoverId('')
    }
  }

  const handleShowDeleteTable = (id: string) => {
    setHoverId(id)
  }

  const handleDelete = (id: string) => {
    if (window.confirm('削除してもよろしいですか？')) {
      deleteEmployee(id).then(() => {
        getEmployees().then((res) => {
          setEmployees(
            res.data.data?.map((employee: any) => {
              delete employee['createdAt']
              delete employee['updatedAt']
              employee['status'] = status_hash[employee['status']]
              return employee
            })
          );
        });
      })
    }
  }

  return (
    <React.Fragment>
      <Box style={{ width: '85%', marginTop: '2.5%', margin: '5% auto 1% auto', textAlign: 'right' }}>
        <Link style={{ textDecoration: 'none' }} href='/admin/employee/new' underline="none">
          <Button variant="contained" style={{ background: "#87c9a7" }}>
            新規作成
          </Button>
        </Link>
      </Box>
      <Paper style={{ width: '85%', overflow: 'hidden', margin: '0 auto' }}>
        <TableContainer sx={{ maxHeight: 630 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {employees
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i} onMouseOver={() => handleShowDeleteTable(row.id)} onMouseLeave={() => handleHideDeleteTable(row.id)}>
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          column.id === 'name'
                            ?
                            <TableCell key={column.id} align={column.align} onClick={() => handleGoToShowPage(row.id)}>
                              <Link style={{ textDecoration: 'none', color: 'black' }} component="button">
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </Link>
                            </TableCell>
                              :
                            <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                        );
                      })}
                      {hoverId === row.id
                        ?
                        <TableCell align={'right'}>
                          <Tooltip title="編集" style={{ margin: '0 10%' }}>
                            <IconButton onClick={() => handleGoToEditPage(row.id)}>
                              <EditIcon sx={{ color: 'skyblue' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="削除">
                            <IconButton onClick={() => handleDelete(row.id)}>
                              <DeleteIcon sx={{ color: 'tomato' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        :
                        <TableCell />
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          component="div"
          count={employees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
