import { useEffect, useState } from 'react';
import EmployeeTable from '../../components/tables/employe_table';
import AttendanceTable from '../../components/tables/attendance_table';
import { getEmployeesAttendancesData, getOldestYear } from 'lib/api/api';
import { Container, Grid } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AttendanceSumTable from 'components/tables/attendance_sum_table';
import { Box, Button} from '@mui/material';
import { EmployeeDataParams } from 'interfaces';
import { Link } from 'react-router-dom';

export default function AdminTop() {

  const [employees_attendances, setEmployeesAttendances] = useState<[]>([])

  const now = new Date()
  const [month, setMonth] = useState<string>(String(now.getMonth() + 1))
  const [year, setYear] = useState<string>(String(now.getFullYear()))

  const month_selects = [...Array(12)]?.map((_, i) => i + 1)
  const [yearSelects, setYearSelects] = useState<number[]>([2023])


  const createYearSelecter = (oldest_year: number) => {
    let year_selecter = []
    do {
      year_selecter.push(oldest_year++)
    }
    while (oldest_year <= now.getFullYear())
    setYearSelects(year_selecter)
  }

  useEffect(() => {
    const params: EmployeeDataParams = {
      year: year,
      month: month
    }
    getEmployeesAttendancesData(params).then((res) => {
      setEmployeesAttendances(res.data)
    })
    getOldestYear().then((res) => {
      createYearSelecter(res.data['oldestYear'])
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year])

  const handleMonthChange = (event: SelectChangeEvent) => {
    setMonth(event.target.value);
  };
  const handleYearChange = (event: SelectChangeEvent) => {
    setYear(event.target.value);
  };
  

  return (
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box sx={{
          minWidth: '100%',
          display: 'flex'
        }}>
          <FormControl sx={{ marginTop: '3%', minWidth: 120, backgroundColor: 'white' }}>
            <InputLabel>年選択</InputLabel>
            <Select
              value={year}
              label="年"
              onChange={handleYearChange}
            >
              {yearSelects?.map((i) =>
                <MenuItem key={i} value={i}> {i} 年</MenuItem>
              )}
              <MenuItem key={2000} value={9999}>総合</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: '3%', marginLeft: '1%', minWidth: 120, backgroundColor: 'white' }}>
            <InputLabel>月選択</InputLabel>
            <Select
              value={month}
              onChange={handleMonthChange}
            >
              {month_selects?.map((i) =>
                <MenuItem key={i} value={i}> {i} 月</MenuItem>
              )}
              <MenuItem key={2000} value={now.getFullYear()}>総合</MenuItem>
            </Select>
          </FormControl>
          <div style={{ marginLeft: 'auto', marginTop: '3%' }}>
            <Link style={{ textDecoration: 'none' }} to={'/admin/attendance/new'} >
              <Button variant="contained" style={{ background: "#87c9a7", width: '145px', height: '50px', fontSize: '16px' }}>
                出面新規登録
              </Button>
            </Link>
          </div>

        </Box>
      </Grid>
      <Grid container spacing={0}>
        <AttendanceTable year={year} month={month} />
      </Grid>
      <AttendanceSumTable year={year} month={month} />
      <Grid container spacing={1}>
        {employees_attendances?.map((employee_attendances) => (
          <EmployeeTable key={employee_attendances['employeeId']} employee_attendances={employee_attendances} width_adjuster={'49%'}/>
        ))}
      </Grid>
    </Container>
  );
}