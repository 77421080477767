import { Fragment, useEffect, useState } from 'react';
import { getAdminTop } from 'lib/api/api';
import { AdminTopParams, AdminTopTypes } from 'interfaces/index'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function AttendanceSumTable(props: { year: string, month: string }) {

  const [fee_sum, setFeeSum] = useState<AdminTopTypes['contructFee']>('')
  const [overtime_hours_sum, setOvertimeHoursSum] = useState<AdminTopTypes['overtimeHours']>('')
  const [employee_cost_sum, setEmployeeCostSum] = useState<AdminTopTypes['employeeCost']>('')
  const [material_cost_sum, setMaterialCostSum] = useState<AdminTopTypes['materialCost']>('')
  const [profit_sum, setProfitSum] = useState<AdminTopTypes['profit']>('')
  const [ninku_sum, setNinkuSum] = useState<AdminTopTypes['ninku']>('')
  const [discount_sum, setDiscountSum] = useState<AdminTopTypes['discount']>('')
  const [additional_works_sum, setAdditionalWorksSum] = useState<AdminTopTypes['additionalWorks']>('')

  const handleSetRows = (res: any) => {
    setFeeSum(res.data?.map((row: AdminTopTypes) => {
      return row['contructFee']
    }).reduce((a: any, b: any) => {
      return a + b;
    }).toLocaleString() + "円");

    setMaterialCostSum(res.data?.map((row: AdminTopTypes) => {
      return row['materialCost']
    }).reduce((a: any, b: any) => {
      return a + b;
    }).toLocaleString() + "円");

    setProfitSum(res.data?.map((row: AdminTopTypes) => {
      return row['profit']
    }).reduce((a: any, b: any) => {
      return Number(a) + Number(b);
    }).toLocaleString() + "円");

    setNinkuSum(res.data?.map((row: AdminTopTypes) => {
      return row['ninku']
    }).reduce((a: any, b: any) => {
      return a + b;
    }));
  
    setAdditionalWorksSum(res.data?.map((row: AdminTopTypes) => {
      return row['additionalWorks']
    }).reduce((a: any, b: any) => {
      return a + b;
    }));
  
    setOvertimeHoursSum(res.data?.map((row: AdminTopTypes) => {
      return row['overtimeHours']
    }).reduce((a: any, b: any) => {
      return Number(a) + Number(b);
    }).toLocaleString());

    setEmployeeCostSum(res.data?.map((row: AdminTopTypes) => {
      return row['employeeCost']
    }).reduce((a: any, b: any) => {
      return Number(a) + Number(b);
    }).toLocaleString());

    setDiscountSum(res.data?.map((row: AdminTopTypes) => {
      return row['discount']
    }).reduce((a: any, b: any) => {
      return Number(a) + Number(b);
    }).toLocaleString() + "円");

  }

  useEffect(() => {
    const params: AdminTopParams = {
      year: props.year,
      month: props.month,
    }
    getAdminTop(params).then((res) => {
      if (res.data.length > 0) {
        handleSetRows(res)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.month, props.year])

  return (
    <Fragment>
      <Paper style={{ width: '35%', overflow: 'hidden', marginTop: '1%', marginBottom: '1%', marginRight: 'auto' }}>
        <TableContainer component={Paper} sx={{ maxHeight: 680, minWidth: '100%' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{width: '60%' }} />
                <TableCell
                  style={{ fontWeight: 'bold', textAlign: 'left' }}
                >
                  合計
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>契約金</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{fee_sum}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>人工</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{ninku_sum}人工</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>追加工事</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{additional_works_sum}人工</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>残業時間</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{overtime_hours_sum}時間</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>人件費</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{employee_cost_sum}円</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>材料費</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{material_cost_sum}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>値引き</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{discount_sum}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>利益</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>{profit_sum}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
}
