import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAdminUser, getAdminUsers } from 'lib/api/api';
import { AdminUser } from 'interfaces';
import { Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Column {
  id: 'email' | 'status' | 'companyName';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'companyName', label: '会社名', minWidth: 170 },
  { id: 'email', label: 'メールアドレス', minWidth: 170 },
  { id: 'status', label: '状態', minWidth: 70 },
];

const admin_user_status_hash = {'0': "無効", '1': '管理者', '2': 'スタッフ', '99': '開発者'} as any

export default function IndexAdminUsers() {
  const navigate = useNavigate()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const [admin_users, setAdminUsers] = React.useState<AdminUser[]>([])
  const [hoverId, setHoverId] = React.useState<string>('');
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleGoToEditPage = (id: string) => {
    navigate('/super_admin/admin_user/edit/' + id, { state: { adminUserId: id } })
  }

  React.useEffect(()=>{
    getAdminUsers().then((res) => {
      setAdminUsers(
        res.data?.map((admin_user: any) => {
        delete admin_user['createdAt']
        delete admin_user['updatedAt']
        admin_user['status'] = admin_user_status_hash[admin_user['status']]
        return admin_user
        })
      );
    });
  }, [])

  const handleHideDeleteTable = (id: string) => {
    if (hoverId === id) {
      setHoverId('')
    }
  }

  const handleShowDeleteTable = (id: string) => {
    setHoverId(id)
  }

  const handleDelete = (id: string) => {
    if (window.confirm('削除してもよろしいですか？')) {
      deleteAdminUser(id).then(() => {
        getAdminUsers().then((res) => {
          setAdminUsers(
            res.data?.map((admin_user: any) => {
              delete admin_user['createdAt']
              delete admin_user['updatedAt']
              if (admin_user['status'] === 1) {
                admin_user['status'] = '有効'
              } else if (admin_user['status'] === 0) {
                admin_user['status'] = '無効'
              }
              return admin_user
            })
          );
        });
      })
    }
  }

  return (
    <React.Fragment>
      <Box style={{ width: '85%', marginTop: '2.5%', margin: '5% auto 1% auto', textAlign: 'right' }}>
        <Link style={{ textDecoration: 'none' }} to={'/super_admin/admin_user/new'} >
          <Button variant="contained" style={{ background: "#87c9a7" }}>
            新規作成
          </Button>
        </Link>
      </Box>
      <Paper style={{ width: '85%', overflow: 'hidden', margin: '0 auto' }}>
        <TableContainer sx={{ maxHeight: 630 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {admin_users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row: any, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i} onMouseOver={() => handleShowDeleteTable(row.id)} onMouseLeave={() => handleHideDeleteTable(row.id)}>
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      {hoverId === row.id
                        ?
                        <TableCell align={'right'}>
                          <Tooltip title="編集" style={{ margin: '0 10%' }}>
                            <IconButton onClick={() => handleGoToEditPage(row.id)}>
                              <EditIcon sx={{ color: 'skyblue' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="削除">
                            <IconButton onClick={() => handleDelete(row.id)}>
                              <DeleteIcon sx={{ color: 'tomato' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        :
                        <TableCell />
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          component="div"
          count={admin_users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
