import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AlerMessage, SignUpParams } from "interfaces/index";
import MenuItem from '@mui/material/MenuItem';
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getCurrentUser} from 'lib/api/auth';
import { editAdminUser, updateAdminUser } from 'lib/api/api';

const theme = createTheme();

export default function EditAdminUser() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<SignUpParams["email"]>('')
  const [password, setPassword] = useState<SignUpParams["password"]>('')
  const [password_confirmation, setPasswordConfirmation] = useState<SignUpParams["password"]>('')
  const [company_id, setCompanyId] = useState<SignUpParams["company_id"]>("")

  const [status, setStatus] = useState<SignUpParams["status"]>("1")
  const [admin_status, setAdminStatus] = useState<number>()

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  useEffect(() => {
    if (location.state && location.state.adminUserId) {
      editAdminUser(location.state.adminUserId).then((res) => {
        setEmail(res.data.data.email)
        setCompanyId(String(res.data.data.companyId))
        setStatus(String(res.data.data.status))
      })
    } else {
      navigate('/super_admin/admin_users', { state: { alert_message: '不正なアクセスです', alert_severity: "error", alert_open: true } })
    }
    getCurrentUser().then((res) => {
      setAdminStatus(res.data.data.status)
    })
  }, [location.state, navigate])

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      staus: data.get('status'),
    });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };


  const handleUpdateAdminUser = async () => {
    const params: SignUpParams = {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      status: status,
      company_id: company_id
    };

    try {
      const res = await updateAdminUser(location.state.adminUserId, params)

      if (res.status === 200) {
        // 登録成功
        navigate("/super_admin/admin_users", { state: { alert_message: "[管理ユーザー] " + email + " を編集しました", alert_severity: 'success', alert_open: true } })
      }else {
        handleAlertMessage("登録できませんでした", 'error')
      }
    } catch (err) {
      console.log(err)
      handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
    }
  }

  const KeyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleUpdateAdminUser();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            管理ユーザー編集
          </Typography>
          <Box component="form" onKeyDown={(e) => KeyPress(e)} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              value={email? email : ''}
              id="Email"
              label="Email"
              name="Email"
              onChange={event => {
                setEmail(event.target.value)
              }}
              autoComplete='off'
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              onChange={event => {
                setPassword(event.target.value)
              }}
              autoComplete='off'
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordConfirmation"
              label="パスワード確認"
              type="password"
              id="passwordConfirmation"
              onChange={event => {
                setPasswordConfirmation(event.target.value)
              }}
              autoComplete='off'
            />
            {
              admin_status === 99 &&
              <>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">ステータス</InputLabel>
                  <Select
                    value={status ? status : ""}
                    onChange={handleStatusChange}
                    autoWidth
                    label="ステータス"
                  >
                    <MenuItem value={'99'}>開発者</MenuItem>
                    <MenuItem value={'2'}>スタッフ</MenuItem>
                    <MenuItem value={'1'}>管理者</MenuItem>
                    <MenuItem value={'0'}>無効</MenuItem>
                  </Select>
                </FormControl>
                {/* <div>
                  <FormControl sx={{ m: 1, minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">クライアント会社</InputLabel>
                    <Select
                      value={company_id ? company_id : ""}
                      onChange={handleCompanyId}
                      autoWidth
                      label="クライアント会社"
                    >
                      {companies?.map((c: any) =>
                        <MenuItem key={c.id} value={String(c.id)}>{c.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div> */}
              </>
            }
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleUpdateAdminUser}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}