import client from "lib/api/client"

import { SignUpParams, SignInParams } from "interfaces/index"
import { createHeaders } from "lib/shared_function/create_headers"

// サインアップ（新規アカウント作成）
export const signUp = (params: SignUpParams) => {
  return client.post("auth", {registration: params}, { headers: createHeaders() })
}

// サインイン（ログイン）
export const signIn = (params: SignInParams) => {
  return client.post("auth/sign_in", params)
}

// サインアウト（ログアウト）
export const signOut = () => {
  return client.delete("auth/sign_out", {
    headers: createHeaders(),
  })
}

// 認証済みのユーザーを取得
export const getCurrentUser = () => {
  return client.get("/auth/sessions", {
    headers: createHeaders()
  })
}

// 会社ステータス取得
export const getCompanyStatus = () => {
  return client.get("/auth/sessions/get_company_status", { headers: createHeaders() })
}