import { Fragment, useEffect, useState } from 'react';
import { getAdminTop } from 'lib/api/api';
import { AdminTopParams, AdminTopTypes } from 'interfaces/index'
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

interface Column {
  id: 'name' | 'ninku'| 'additionalWorks' | 'overtimeHours' | 'employeeCost' | 'contructFee' | 'materialCost' | 'profit' | 'discount';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}


const columns: readonly Column[] = [
  { id: 'name', label: '現場名', minWidth: 170 },
  { id: 'contructFee', label: '契約金', minWidth: 70 },
  { id: 'ninku', label: '人工', minWidth: 50 },
  { id: 'additionalWorks', label: '追加工事', minWidth: 50 },
  { id: 'overtimeHours', label: '残業時間', minWidth: 50 },
  { id: 'employeeCost', label: '人件費', minWidth: 50 },
  { id: 'materialCost', label: '材料費', minWidth: 70 },
  { id: 'discount', label: '値引き', minWidth: 70 },
  { id: 'profit', label: '利益', minWidth: 70 },
];

export default function AttendanceTable(props: { year: string, month: string }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);

  const [rows, setRows] = useState<AdminTopTypes[]>([])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetRows = (res: any) => {
    setRows(res.data?.map((row: AdminTopTypes) => {
      row['contructFee'] = row['contructFee'].toLocaleString() + "円"
      row['overtimeHours'] = row['overtimeHours'].toLocaleString() + "時間"
      row['employeeCost'] = row['employeeCost'].toLocaleString() + "円"
      row['materialCost'] = row['materialCost'].toLocaleString() + "円"
      row['profit'] = row['profit'].toLocaleString() + "円"
      row['ninku'] = row['ninku'].toLocaleString() + "人工"
      row["discount"] = row['discount'].toLocaleString() + "円"
      row['additionalWorks'] = row['additionalWorks'].toLocaleString() + "人工"
      return row
    }))
  }

  useEffect(() => {
    const params: AdminTopParams = {
      year: props.year,
      month: props.month,
    }
    getAdminTop(params).then((res) => {
      if (res.data?.length > 0) {
        handleSetRows(res)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.month, props.year])

  return (
    <Fragment>
      <Paper style={{ width: '100%', overflow: 'hidden', margin: '1% auto' }}>
        <TableContainer component={Paper} sx={{ maxHeight: 680, minWidth: '100%' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i} >
                      {columns?.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
}
