import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { editDiscount, getConstructionSites, updateDiscount } from "lib/api/api";
import { AlerMessage, DiscountParams } from "interfaces/index";
import CustomizedSnackbars from 'components/snackbar';
import { FormControl, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const theme = createTheme();

export default function EditDiscount() {
  const navigate = useNavigate();
  const location = useLocation()
  // const [is_valid, setIsValid] = useState<boolean>(false);

  const [construction_sites, setConstructionSites] = useState<[]>([])

  const [price, setPrice] = useState<DiscountParams["price"]>("")
  const [construction_site_id, setConstructionSiteId] = useState<DiscountParams["construction_site_id"]>('')

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () => {
    if (alert_open) {
      setAlertOpen(false);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (location.state && location.state.discountId) {
      getConstructionSites().then((res) => {
        const sites = res.data.data.filter((construction_site: any) =>
          construction_site["status"] !== 0
        )
        setConstructionSites(sites?.map((construction_site: any) => {
          delete construction_site['createdAt']
          delete construction_site['updatedAt']
          delete construction_site['status']
          return construction_site
        }))
      })
      editDiscount(location.state.discountId).then((res) => {
        setPrice(String(res.data.price))
        setConstructionSiteId(res.data.constructionSiteId)
      })
    } else {
      navigate('/admin/discounts', { state: { alert_message: '不正なアクセスです', alert_severity: "error", alert_open: true } })
    }
  }, [location.state, navigate])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      id: data.get('id'),
      staus: data.get('status'),
    });
  };

  const handleCreateDiscount = async () => {
    if (price && construction_site_id) {
      const params: DiscountParams = {
        price: price,
        construction_site_id: construction_site_id,
      };

      try {
        const res = await updateDiscount(location.state.discountId, params)

        if (res.status === 200) {
          // 登録成功
          navigate("/admin/discounts", { state: { alert_message: "[値引き]を変更しました", alert_severity: 'success', alert_open: true } })
        } else {
          handleAlertMessage("登録できませんでした", 'error')
        }
      } catch (err) {
        console.log(err)
        handleAlertMessage("何らかのエラー！登録できませんでした！", 'error')
      }
    }
  }

  const handleConstructionSiteChange = (id: any) => {
    setConstructionSiteId(id);
  };

  const KeyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleCreateDiscount();
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            材料編集
          </Typography>
          <Box component="form" onKeyDown={(e) => KeyPress(e)} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl sx={{ my: 1, minWidth: '100%' }}>
              <Autocomplete
                id="construction_site_select"
                sx={{ minWidth: 100 }}
                options={construction_sites ? construction_sites : []}
                autoHighlight
                getOptionLabel={(option: any) => option ? option.name : ''}

                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(event: any, newValue: any) => {
                  const construction_site_id = newValue ? newValue.id : ''
                  handleConstructionSiteChange(construction_site_id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="現場"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                value={
                  construction_sites.length > 0 ?
                    construction_site_id ? construction_sites.find((cs) => cs['id'] === construction_site_id) : null
                    :
                    null
                }
              />
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="price"
              value={price}
              label="値引額"
              InputProps={{
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}
              onChange={event => {
                setPrice(event.target.value)
              }}
              autoFocus
              helperText="※半角数字"
            />
            <Button
              fullWidth
              variant="contained"
              disabled={false}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleCreateDiscount}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}