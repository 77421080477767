import {
  createBrowserRouter,
} from "react-router-dom";

// Pages
import Root from "routes/root";
import ErrorPage from 'pages/error';
import SuperAdmin from 'routes/super_admin';
import Admin from 'routes/admin';
import CreateEmployee from 'pages/admin/employee/new';
import SuperAdminTop from 'pages/super_admin/top';
import AdminTop from 'pages/admin/top';
import CreateMaterialShop from 'pages/admin/material_shop/new';
import CreateConstructionSite from 'pages/admin/construction_site/new';
import IndexEmployees from 'pages/admin/employee/index'
import IndexMaterialShops from "pages/admin/material_shop";
import IndexConstructionSites from "pages/admin/construction_site";
import IndexAttendances from "pages/admin/attendance";
import IndexMaterials from "pages/admin/material";
import CreateAttendance from "pages/admin/attendance/new";
import CreateMaterial from "pages/admin/material/new";
import EditAttendance from "pages/admin/attendance/edit";
import EditMaterial from "pages/admin/material/edit";
import EditEmployee from "pages/admin/employee/edit";
import EditMaterialShop from "pages/admin/material_shop/edit";
import EditConstructionSite from "pages/admin/construction_site/edit";
import CreateCompany from "pages/super_admin/company/new";
import CreateAdminUser from "pages/super_admin/admin_user/new";
import IndexAdminUsers from "pages/super_admin/admin_user";
import EditAdminUser from "pages/super_admin/admin_user/edit";
import ShowEmployee from "pages/admin/employee/show";
import IndexDiscounts from "pages/admin/discount";
import CreateDiscount from "pages/admin/discount/new";
import EditDiscount from "pages/admin/discount/edit";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  // 開発者・アプリ管理人用画面
  {
    path: "/super_admin",
    element: <SuperAdmin />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/super_admin",
        element: <SuperAdminTop />
      },
      // クライアント会社
      // {
      //   path: "/super_admin/attendances",
      //   element: <IndexAttendances />
      // },
      {
        path: "/super_admin/company/new",
        element: <CreateCompany />
      },
      {
        path: "/super_admin/company/edit/:id",
        element: <EditAttendance />
      },
      // 管理者
      {
        path: "/super_admin/admin_users",
        element: <IndexAdminUsers />
      },
      {
        path: "/super_admin/admin_user/new",
        element: <CreateAdminUser />
      },
      {
        path: "/super_admin/admin_user/edit/:id",
        element: <EditAdminUser />
      }
    ]
  },
  // クライアント用管理画面
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/admin",
        element: <AdminTop />
      },
      // 出面
      {
        path: "/admin/attendances",
        element: <IndexAttendances />
      },
      {
        path: "/admin/attendance/new",
        element: <CreateAttendance />
      },
      {
        path: "/admin/attendance/edit/:id",
        element: <EditAttendance />
      },
      // 材料
      {
        path: "/admin/materials",
        element: <IndexMaterials />
      },
      {
        path: "/admin/material/new",
        element: <CreateMaterial />
      },
      {
        path: "/admin/material/edit/:id",
        element: <EditMaterial/>
      },
      // 値引き
      {
        path: "/admin/discounts",
        element: <IndexDiscounts />
      },
      {
        path: "/admin/discount/new",
        element: <CreateDiscount />
      },
      {
        path: "/admin/discount/edit/:id",
        element: <EditDiscount />
      },
      // 従業員
      {
        path: "/admin/employees",
        element: <IndexEmployees />
      },
      {
        path: "/admin/employee/new",
        element: <CreateEmployee />
      },
      {
        path: "/admin/employee/edit/:id",
        element: <EditEmployee />
      },
      {
        path: "/admin/employee/show/:id",
        element: <ShowEmployee />
      },
      // 建材屋
      {
        path: "/admin/material_shops",
        element: <IndexMaterialShops />
      },
      {
        path: "/admin/material_shop/new",
        element: <CreateMaterialShop />
      },
      {
        path: "/admin/material_shop/edit/:id",
        element: <EditMaterialShop />
      },
      // 現場
      {
        path: "/admin/construction_sites",
        element: <IndexConstructionSites />
      },
      {
        path: "/admin/construction_site/new",
        element: <CreateConstructionSite />
      },
      {
        path: "/admin/construction_site/edit/:id",
        element: <EditConstructionSite />
      },
    ]
  },
]);