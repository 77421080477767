import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { ListItemText } from '@mui/material';
import Cookies from "js-cookie"
import { getComapnyName } from 'lib/api/api'
import { getCurrentUser } from 'lib/api/auth';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  {id: 1, name: 'Home', path: '/admin'},
  {id: 2, name: '出面', path: '/admin/attendances' },
  {id: 3, name: '材料', path: '/admin/materials' },
  {id: 4, name: '値引き', path: '/admin/discounts' },
  {id: 5, name: '現場', path: '/admin/construction_sites' },
  {id: 6, name: '建材屋', path: '/admin/material_shops'},
  {id: 7, name: '従業員/応援会社', path: '/admin/employees' },
];
const navItemsSuperAdmin = [
  { id: 1, name: 'Home', path: '/super_admin' },
  { id: 2, name: '会社登録', path: '/super_admin/company/new' },
  { id: 3, name: '会社ユーザー', path: '/super_admin/admin_users' },
];


export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [currentUserStatus, setCurrentUserStatus] = useState<number>();

  useEffect(() => {
    getCurrentUser().then((res) => {
      setCurrentUserStatus(res?.data?.data?.status)
      getComapnyName().then((res) => {
        setCompanyName(res.data["companyName"])
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get('_access_token')])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogOut = () => {
    Cookies.remove('_access_token')
  }

  const drawer_lists= ((item: any) =>
    <Link key={item.id} style={{ textDecoration: 'none', color: '#333333' }} to={item.path}>
      <ListItem disablePadding>
        <ListItemButton sx={{ textAlign: 'center' }}>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </ListItem>
    </Link>
  )

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {companyName} Menu
      </Typography>
      <Divider />
      <List>
        {currentUserStatus === 99
        ?
          navItemsSuperAdmin?.map((item) => (
            drawer_lists(item)
          ))
        :
          navItems?.map((item) => (
            drawer_lists(item)
          ))
        }
        <Link onClick={()=>handleLogOut()} style={{ textDecoration: 'none', color: 'red' }} to={''}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'ログアウト'} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  const header_company_name =((path: string) =>
    <Link style={{ textDecoration: 'none', color: 'white' }} to={path}>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
      >
        {companyName}
      </Typography>
    </Link>
  )

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" style={{ backgroundColor:'rgb(74 112 255 / 92%)'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          {
            currentUserStatus === 99
            ?
              header_company_name('/super_admin')
            :
              header_company_name('/admin')
          }
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}
