import { useState, forwardRef, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar,{ SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AlerMessage } from "interfaces/index";
import { useLocation, useNavigate } from 'react-router-dom';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props: { alert_message: AlerMessage['alert_message'], alert_severity: AlerMessage['alert_severity'], alert_open: AlerMessage['alert_open']}) {
  const navigate = useNavigate()
  const location = useLocation()

  // SnackBarのAlertMessageで使用する
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)

  const { vertical, horizontal }: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const handleClose = () => {
    setAlertOpen(false);
    navigate(location.pathname, { state: { alert_message: "", alert_severity: 'info', alert_open: false } })
  };

  useEffect(()=>{
    setAlertOpen(props.alert_open)
  }, [props.alert_open])

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={alert_open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={6000} onClose={handleClose} >
        <Alert severity={props.alert_severity} sx={{ width: '100%' }} onClose={handleClose}>
          {props.alert_message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
