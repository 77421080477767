import { Fragment, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AdminTopEmployeeData, Employee } from 'interfaces/index'
import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Column {
  id: 'constructionSiteName' | 'attendDays' | 'overtimeHours' | 'cost'
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'constructionSiteName', label: '現場名', minWidth: 120 },
  { id: 'attendDays', label: '日数', minWidth: 30 },
  { id: 'overtimeHours', label: '残業時間', minWidth: 80 },
  { id: 'cost', label: '人件費', minWidth: 90 },
];

export default function EmployeeTable(props: { employee_attendances: any, width_adjuster: string }) {
  const navigate = useNavigate();
  const [rows, setRows] = useState<AdminTopEmployeeData[]>([])
  const [employeeName, setEmployeeName] = useState<Employee['name']>('')

  const [attendDaysSum, setAttendDaysSum] = useState<AdminTopEmployeeData['attendDays']>('')
  const [overtimeHoursSum, setOvertimeHoursSum] = useState<AdminTopEmployeeData['overtimeHours']>('')
  const [costSum, setCostSum] = useState<AdminTopEmployeeData['cost']>('')

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [employee_id, setEmployeeId] = useState<string>('')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetRows = (employee_data: any) => {
    if (employee_data['employeeAttendancesData']?.length > 0) {
      setEmployeeName(employee_data['employeeName'])
      setEmployeeId(employee_data['employeeId'])
      setAttendDaysSum(employee_data['employeeAttendancesData']?.map((row: AdminTopEmployeeData) => {
        return row['attendDays']
      }).reduce((a: any, b: any) => {
        return a + b;
      }).toLocaleString());

      setOvertimeHoursSum(employee_data['employeeAttendancesData']?.map((row: AdminTopEmployeeData) => {
        return Number(row['overtimeHours'])
      }).reduce((a: any, b: any) => {
        return a + b;
      }).toLocaleString());

      setCostSum(employee_data['employeeAttendancesData']?.map((row: AdminTopEmployeeData) => {
        return Number(row['cost'])
      }).reduce((a: any, b: any) => {
        return a + b;
      }).toLocaleString());
  
      setRows(employee_data['employeeAttendancesData']?.map((row: AdminTopEmployeeData) => {
        row['attendDays'] = row['attendDays'].toLocaleString() + "日"
        row['overtimeHours'] = row['overtimeHours'].toLocaleString() + "時間"
        row['cost'] = row['cost'] + "円"
        return row
      }))
    } else {
      setEmployeeName('')
    }
  }

  const adjust_table_rows = () => {
    if (rows.length < 5){
      const teble_rows = [];
      for (let i = 5 - rows.length; i > 0; i--) {
        teble_rows.push(
          <TableRow key={i + 99}>
            <TableCell key={i + 99} colSpan={4}>　</TableCell>
          </TableRow>
        );
      }
      return teble_rows;
    }
  };

  const handleGoToShowPage = (id: string) => {
    if (id){
      navigate('/admin/employee/show/' + id, { state: { employeeId: id } })
    }
  }


  useEffect(() => {
    handleSetRows(props.employee_attendances)
  }, [props.employee_attendances])

  return (
    <Fragment>
      {employeeName?.length > 0
        ?
        <Paper style={{ width: props.width_adjuster, overflow: 'hidden', margin: '3% auto' }}>
          <TableContainer component={Paper} style={{ width: '100%', maxHeight: 450 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={1} onClick={() => handleGoToShowPage(employee_id)}>
                    <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', fontSize: 20 }} underline="none" component="button">
                      {employeeName}
                    </Link>
                  </TableCell>
                  <TableCell colSpan={3} />
                </TableRow>
                <TableRow style={{ top: '570' }} >
                  {columns?.map((column, i) =>
                    <TableCell key={i} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>{column.label}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i} >
                        {columns?.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                            
                          );
                        })}
                      </TableRow>
                    );
                })}
                {
                  adjust_table_rows()
                }
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>合計</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{attendDaysSum}日</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{overtimeHoursSum}時間</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{costSum}円</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        :
        false
      }
    </Fragment>
  );
}
