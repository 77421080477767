import {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getCurrentUser, signIn } from "lib/api/auth";
import { SignInParams, AlerMessage} from "interfaces/index";
import Cookies from 'js-cookie';
import CustomizedSnackbars from 'components/snackbar';

const theme = createTheme();

export default function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  // SnackBarのAlertMessageで使用する
  const [alert_message, setAlertMessage] = useState<AlerMessage["alert_message"]>('')
  const [alert_open, setAlertOpen] = useState<AlerMessage["alert_open"]>(false)
  const [alert_severity, setAlertSeverity] = useState<AlerMessage["alert_severity"]>('success')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const handleAlertMessage = (message: AlerMessage["alert_message"], severity: AlerMessage["alert_severity"]) => {
    setAlertMessage(message)
    setAlertOpen(true)
    setAlertSeverity(severity)
  };

  const handleAlertClose = () =>{
    if (alert_open){
      setAlertOpen(false);
    }else{
      return;
    }
  };

  const handleSignIn = async () =>{
    if (email && password){
      const params: SignInParams = {
        password: password,
        email: email
      };

      signIn(params).then((res) => {
        if (res.status === 200) {
          // ログインに成功した場合はCookieに各値を格納
          Cookies.set("_access_token", res.headers["access-token"] as string, { expires: 1 })
          Cookies.set("_client", res.headers["client"] as string, { expires: 1 })
          Cookies.set("_uid", res.headers["uid"] as string, { expires: 1 })
          navigate("/admin", { state: { alert_message: "ログインしました", alert_severity: 'success', alert_open: true } })
        } else {
          handleAlertMessage("ログインできませんでした", 'error')
        }
      }).catch((err) => {
        console.log(err)
        handleAlertMessage("何らかのエラー！", 'error')
      })
    }
  }

  useEffect(()=>{
    if (location.state !== null) {
      setAlertMessage(location.state.alert_message)
      setAlertSeverity(location.state.alert_severity)
      setAlertOpen(location.state.alert_open)
    }
    getCurrentUser().then((res) => {
      if(res.data.isLogin){
        navigate('/admin', { state: { alert_message: "ログインしています", alert_severity: 'info', alert_open: true } })
      }
    })
  }, [location.state, navigate])

  const KeyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleSignIn();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars alert_message={alert_message} alert_severity={alert_severity} alert_open={alert_open} />
      <Container component="main" maxWidth="xs" onClick={handleAlertClose}>
        <CssBaseline />
        <Box
          onKeyDown={(e) => KeyPress(e)}
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={event => setEmail(event.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={event => setPassword(event.target.value)}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSignIn}
            >
              ログイン
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}